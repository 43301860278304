import { House } from "@phosphor-icons/react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "./Assets/ErrorState.png";

const NotFoundPage = ({ gotoPath }: { gotoPath?: string }) => {
	const navigate = useNavigate();

	const gotoHome = () => {
		if (gotoPath) navigate(gotoPath, { replace: true });
		else navigate("/", { replace: true });
	};

	return (
		<div className="grid min-h-screen place-items-center bg-gray-50">
			<div className="flex flex-col items-center justify-center gap-6">
				<img src={NotFoundImage} alt="Page Not Found" height={210} width={320} />
				<div className="flex flex-col items-center justify-start gap-6">
					<div className="flex flex-col items-center justify-start gap-2">
						<div className="text-center font-['Inter'] text-3xl font-semibold leading-9 text-gray-600">
							Error 404: Page Not Found
						</div>
						<div className="font-['Inter'] text-base font-medium leading-normal text-gray-500">
							The requested page is unavailable. Please navigate back to the dashboard.
						</div>
					</div>
					<Button type="primary" onClick={gotoHome}>
						Return to Dashboard
						<span className="ant-btn-icon">
							<House weight="bold" />
						</span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default NotFoundPage;

