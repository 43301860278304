import { apiInstance } from "api/instance";
import axios from "axios";

type TDashboard = {
	embedDashboardId: string;
	dashboardName: string;
};

export const getEmbedSuperSetDashboards = async () => {
	const dashboards = (await apiInstance.get<ApiResponse<TDashboard[]>>("/super-set-dashboard/")).data.data;
	return dashboards;
};

export const fetchToken = async (dashboardId: string, firstName: string, lastName: string, userId: string) => {
	const { data } = await axios.post("https://superset.stampmyvisa.com/api/v1/security/guest_token/", {
		resources: [
			{
				id: dashboardId,
				type: "dashboard"
			}
		],
		rls: [],
		user: {
			first_name: firstName,
			last_name: lastName,
			username: userId
		}
	});
	return data.token;
};

