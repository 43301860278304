import { useEffect, useRef, useState } from "react";
import { enableDevMode } from "utils/electronEvents";

interface ILogoBar {
	onLogoClick?: () => void;
	imageClassName?: string;
}

export const LogoBar: React.FC<ILogoBar> = ({ onLogoClick }) => {
	const [clickCount, setClickCount] = useState(0);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const handleLogoClick = () => {
		setClickCount(clickCount + 1);
		if (clickCount === 0) {
			timeoutRef.current = setTimeout(() => {
				onLogoClick && onLogoClick();
			}, 500);
		} else if (clickCount > 2) {
			enableDevMode();
		}
	};

	useEffect(() => {
		return () => {
			timeoutRef.current && clearTimeout(timeoutRef.current);
			intervalRef.current && clearInterval(intervalRef.current);
		};
	}, []);

	useEffect(() => {
		if (clickCount > 0) {
			intervalRef.current = setInterval(() => {
				setClickCount(0);
				timeoutRef.current && clearTimeout(timeoutRef.current);
			}, 500);
		}
	}, [clickCount]);

	return (
		<div className="my-8 md:mb-[52px] md:mt-14">
			<img
				width={150}
				height={40}
				onClick={handleLogoClick}
				className="cursor-pointer"
				src="./icons/common/nucleus-logo-full.svg"
				alt="Nucleus Logo"
			/>
		</div>
	);
};

