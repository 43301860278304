import { UserCircle, WarningCircle } from "@phosphor-icons/react";
import { Button, Form, Input } from "antd";
import clsx from "clsx";
import OTPInput from "components/Commons/OTPInput";
import { VALIDATIONS_PATTERNS } from "helpers/validations";
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import useSignInLogic from "./useSignInLogic";

const SignIn = () => {
	const {
		SignInForm,
		onSendVerificationCode,
		userDetails,
		activeStep,
		setActiveStep,
		userNotExist,
		isSubmitting,
		otp,
		setOtp,
		onSubmitOtp,
		otpContainerRef,
		timer,
		onResendOtp,
		isPhone,
		incorrectOTP
	} = useSignInLogic();

	const [isFormInvalid, setIsFormInvalid] = useState(true);
	const [inputType, setInputType] = useState<"phone" | "email" | undefined>();

	useLayoutEffect(() => {
		if (activeStep !== "otp") return;
		setTimeout(() => {
			const inputs = otpContainerRef.current?.querySelectorAll("input");

			if (inputs && inputs.length === 4) {
				if (inputs[3].value) {
					inputs[3].focus();
				} else if (inputs[2].value) {
					inputs[2].focus();
				} else if (inputs[1].value) {
					inputs[1].focus();
				} else {
					inputs[0].focus();
				}
			}
		}, 300);
	}, [activeStep, otpContainerRef]);

	const errorBannerRef = document.getElementById("auth-error-banner");

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (/^\d/.test(value)) {
			setInputType("phone");
			if (value && value.length > 10) {
				e.target.value = value.slice(0, 10);
			}
		} else {
			setInputType("email");
		}
	};

	return (
		<>
			<div
				className={clsx(
					"flex h-full w-full flex-col transition-all duration-300 ease-5",
					activeStep === "main"
						? "visible translate-x-0 opacity-100"
						: "pointer-events-none invisible absolute -translate-x-full opacity-0"
				)}>
				<div className="mb-6 inline-flex h-5  items-center justify-center gap-2 md:mb-8">
					<div className="flex h-5 shrink grow basis-0 items-center justify-start gap-1.5">
						<UserCircle className="relative h-5 w-5" />
						<div className="font-['Inter Display'] text-[17px] font-semibold leading-tight text-gray-800">
							Login
						</div>
					</div>
				</div>
				<Form
					form={SignInForm}
					onFieldsChange={(changedFields, allFields) => {
						const isInvalid = allFields.some((field: any) => {
							return field.errors.length > 0 || !field.value || field.value === "";
						});
						setIsFormInvalid(isInvalid);
					}}
					onFinish={onSendVerificationCode}
					className="grid grid-cols-2 place-items-stretch gap-x-4 gap-y-5"
					layout="vertical">
					<Form.Item
						name="phoneEmailInput"
						className="col-span-2 text-sm font-medium leading-tight text-slate-700"
						label="Phone number or E-mail"
						required
						validateStatus={userNotExist ? "error" : undefined}
						validateTrigger={["onBlur", "onChange", "onSubmit"]}
						hasFeedback={{
							icons: () => {
								return {
									error: <WarningCircle size={18} weight="bold" className="text-rose-600" />,
									success: false,
									validating: false,
									warning: false,
									default: false
								};
							}
						}}
						rules={[
							{
								validateTrigger: ["onBlur", "onSubmit"],
								validator: async (_, value) => {
									if (!value) return Promise.reject("Phone number or E-mail is required!");
									if (value.length === 0)
										return Promise.reject("Phone number or E-mail is required!");
									if (value.length > 0 && value.length < 6)
										return Promise.reject("Please enter a valid phone number or email!");
									const isPhone = VALIDATIONS_PATTERNS.phoneNumber.test(value);
									const isEmail = VALIDATIONS_PATTERNS.validEmail.test(value);
									if (isPhone || isEmail) {
										return Promise.resolve();
									} else {
										return Promise.reject("Please enter a valid phone number or email!");
									}
								}
							}
						]}>
						<Input
							autoFocus
							maxLength={inputType === "phone" ? 10 : undefined}
							prefix={inputType === "phone" ? <div className="whatsapp-icon" /> : undefined}
							onChange={handleInputChange}
						/>
					</Form.Item>

					<Button
						className="col-span-2 mt-3"
						htmlType="submit"
						type="primary"
						loading={isSubmitting}
						disabled={isFormInvalid || userNotExist}>
						Send Verification Code
					</Button>
				</Form>
			</div>

			<div
				ref={otpContainerRef}
				className={clsx(
					"flex h-full w-full flex-col transition-all duration-300 ease-5",
					activeStep === "otp"
						? "visible translate-x-0 opacity-100"
						: "pointer-events-none invisible absolute translate-x-full opacity-0"
				)}>
				<div className="inline-flex w-[350px] flex-col items-start justify-start gap-3">
					<div role="button" className="back-button cursor-pointer" onClick={() => setActiveStep("main")} />
					<div className="flex flex-col items-start justify-start gap-2 self-stretch">
						<div className="font-['Inter Display'] text-[17px] font-semibold leading-tight text-gray-800">
							Check your phone for OTP
						</div>
						<div className="self-stretch leading-4 tracking-[-0.04px]">
							<span className="font-['Inter'] text-[13px] font-medium text-gray-500">
								Please enter the 4-digit code sent to your
							</span>
							<span className="mx-1 font-['Inter'] text-[13px] font-medium text-gray-800">
								{userDetails?.phone ?? ""}
								{userDetails?.email ?? ""}.
							</span>
							<span
								role="button"
								onClick={() => setActiveStep("main")}
								className="cursor-pointer font-['Inter'] text-[13px] font-normal text-indigo-600 underline">
								Change
							</span>
						</div>
					</div>
				</div>
				<div className="py-8">
					<OTPInput
						clearField={otp === ""}
						hasError={incorrectOTP}
						onComplete={onSubmitOtp}
						onChange={(val) => setOtp(val)}
					/>
					{incorrectOTP && (
						<span className="mt-3 block leading-4 md:hidden">
							<span className="font-['Inter'] text-xs font-normal  tracking-tight text-rose-600">
								Wrong OTP!&nbsp;
							</span>
							<span
								onClick={onResendOtp}
								className="cursor-pointer font-['Inter'] text-xs font-medium tracking-tight text-rose-600 underline">
								Resend
							</span>
						</span>
					)}
				</div>

				<Button
					onClick={() => onSubmitOtp(otp)}
					className="col-span-2 my-4"
					type="primary"
					loading={isSubmitting}
					disabled={otp.length < 4}>
					Verify Code
				</Button>

				<div className="self-center leading-5 tracking-[-0.196px]">
					<span className="font-['Inter Display'] text-sm font-normal text-gray-500">
						Didn’t receive a code?
					</span>
					<span className="font-['Inter Display'] text-sm font-normal text-indigo-600"> </span>
					<button
						onClick={onResendOtp}
						disabled={timer > 0}
						className="font-['Inter Display'] cursor-pointer text-sm font-normal leading-5 text-primary-600 disabled:cursor-not-allowed disabled:text-gray-400 [&:not(:disabled)]:underline">
						Resend
						{timer > 0 && (
							<span className="ms-1">
								· <span className="tabular-nums">{timer}</span>s
							</span>
						)}
					</button>
				</div>
			</div>

			{/* active step pills */}
			<div className="absolute inset-x-0 bottom-0 flex h-4 items-end justify-center gap-1.5 ">
				<div
					className={clsx(
						"h-[3px] w-6 rounded-[2px] transition-all duration-300 ease-5",
						activeStep === "main"
							? "bg-primary-600 shadow-[0px_0px_0px_0.5px_#FFF,_0px_0px_0px_0.2px_rgba(32,_41,_57,_0.20)_inset]"
							: "bg-slate-300 shadow-[0px_0px_0px_0.5px_#FFF,_0px_0px_0px_0.2px_rgba(32,_41,_57,_0.20)_inset]"
					)}
				/>
				<div
					className={clsx(
						"h-[3px] w-6 rounded-[2px] transition-all duration-300 ease-5",
						activeStep === "otp"
							? "bg-primary-600 shadow-[0px_0px_0px_0.5px_#FFF,_0px_0px_0px_0.2px_rgba(32,_41,_57,_0.20)_inset]"
							: "bg-slate-300 shadow-[0px_0px_0px_0.5px_#FFF,_0px_0px_0px_0.2px_rgba(32,_41,_57,_0.20)_inset]"
					)}
				/>
			</div>

			{errorBannerRef &&
				userNotExist &&
				createPortal(
					<div className="grid h-full grid-rows-1 place-items-center">
						<div className="flex items-center gap-3 px-5 py-1 font-medium text-white">
							<WarningCircle size={18} weight="bold" className="min-w-[16px]" />
							<span className="place-self-stretch border-x border-l-rose-800 border-r-rose-400" />
							<span className="hidden text-[15px] leading-[18px] text-white md:block">
								This account does not exist. Please try creating a new account.
							</span>
							<span className="block text-[13px] leading-4 md:hidden">
								An account with this {isPhone ? "number" : "email"} does not exist
							</span>
						</div>
					</div>,
					errorBannerRef
				)}

			{errorBannerRef &&
				incorrectOTP &&
				createPortal(
					<div className="grid h-full grid-rows-1 place-items-center">
						<div className="flex items-center gap-3 px-5 py-1 font-medium text-white">
							<WarningCircle size={18} weight="bold" className="min-w-[16px]" />
							<span className="place-self-stretch border-x border-l-rose-800 border-r-rose-400" />
							<span className="hidden text-[15px] leading-[18px] text-white md:block">
								Incorrect OTP. Make sure you entering the correct code.{" "}
							</span>
							<span className="block text-[13px] leading-4 md:hidden">
								Incorrect OTP. Please try again!
							</span>
						</div>
					</div>,
					errorBannerRef
				)}
		</>
	);
};

export default SignIn;

