import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export const setupRequestInterceptors = (
	instance: AxiosInstance,
	cb?: (config: InternalAxiosRequestConfig<any>) => void,
	errorCb?: (error: any) => void
) => {
	instance.interceptors.request.use(
		(config) => {
			config.headers.Authorization = localStorage.getItem("nucleus-jwt-token");
			cb && cb(config);
			return config;
		},
		(error) => {
			errorCb && errorCb(error);
			return Promise.reject(error);
		}
	);
};

export const setupResponseInterceptors = (
	instance: AxiosInstance,
	cb?: (config: AxiosResponse<any, any>) => void,
	errorCb?: (error: any) => void
) => {
	instance.interceptors.response.use(
		(response) => {
			cb && cb(response);
			return response;
		},
		(error) => {
			errorCb && errorCb(error);
			return Promise.reject(error);
		}
	);
};

