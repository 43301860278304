import MainLayout from "components/Layouts/MainLayout";
import { Route, Routes as Router } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./Routes";

export default function Routes() {
	return (
		<Router>
			<Route path="/" element={<MainLayout />}>
				{PRIVATE_ROUTES.map((route, index) => (
					<Route key={index} {...route} />
				))}
			</Route>
			{PUBLIC_ROUTES.map((route, index) => (
				<Route key={index} {...route} />
			))}
		</Router>
	);
}

