import { ArrowRight, CircleNotch, Globe, Play } from "@phosphor-icons/react";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { Button } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useAuthContext } from "pages/Auth/Context";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { getChangeLogs } from "./Api";
import LargePreview from "./Components/LargePreview";

const queryClient = new QueryClient();

const Changelog = () => {
	const navigate = useNavigate();
	const { user } = useAuthContext();

	const { data: changeLogs, isFetching } = useQuery(
		{
			queryKey: ["changelog"],
			queryFn: getChangeLogs,
			initialData: []
		},
		queryClient
	);

	useEffect(() => {
		Mixpanel.track(MIXPANEL_EVENTS.CHANGE_LOG_PAGE_VIEWED);
		Mixpanel.time_event(MIXPANEL_EVENTS.CHANGE_LOG_PAGE_DISMISSED);
		return () => Mixpanel.track(MIXPANEL_EVENTS.CHANGE_LOG_PAGE_DISMISSED);
	}, []);

	const handleOnStartClick = () => {
		if (user) navigate("/");
		else navigate("/auth/sign-in");
	};

	return (
		<section className="bg-gradient-to-br from-slate-950 to-slate-900 relative h-screen w-screen grid grid-cols-[480px_1fr] overflow-hidden">
			<div className="px-10 pr-32 bg-slate-100 flex items-center relative">
				<div className="grid gap-6">
					<div className="flex items-center gap-2">
						<img
							src="./icons/common/nucleus-logo-full.svg"
							alt="Nucleus logo"
							title="Nucleus Logo" // Tooltip for the logo
						/>

						<div className="flex h-8 items-center rounded overflow-hidden">
							<span className="h-full w-0.5 bg-primary-600"></span>
							<span className="h-full w-0.5 bg-primary-200"></span>
						</div>

						<Button type="primary" className="ms-2.5 !h-6" onClick={handleOnStartClick}>
							Start
							<ArrowRight size={16} className="ms-2" />
						</Button>
					</div>
					<div className="text-lg ps-2 leading-6 font-light text-gray-600 text-pretty ">
						Manage and monitor visa applications through our
						<br />
						<span className="text-primary-600">Intuitive Internal Dashboard.</span>
					</div>
				</div>

				<div className="absolute bottom-5 text-xs font-normal leading-6 text-gray-500 flex items-center">
					Brought to you by
					<Link
						className="ms-1.5 text-gray-700 hover:text-primary-700 font-medium inline-flex items-center gap-1"
						to="https://stampmyvisa.com/home/itinerary"
						target="_blank">
						<Globe className="inline" fontWeight={600} /> StampMyVisa
					</Link>
				</div>
			</div>

			<section className="absolute pointer-events-none inset-0 grid grid-cols-[480px_1fr] overflow-y-auto">
				<div className="relative pointer-events-auto col-start-2 flex flex-col-reverse px-16 py-28 gap-8">
					<svg height={100} width={100} className="  absolute left-0 h-full w-1.5 " aria-hidden="true">
						<defs>
							<pattern id=":R7lm:" width="6" height="8" patternUnits="userSpaceOnUse">
								<path d="M0 0H6M0 8H6" className="stroke-slate-100/50" fill="none"></path>
							</pattern>
						</defs>
						<rect width="100%" height="100%" fill="url(#:R7lm:)"></rect>
					</svg>

					{isFetching && (
						<CircleNotch size={20} className="animate-spin text-zinc-100 fixed top-5 right-10" />
					)}

					{changeLogs.map((log, changeLogsIndex) => {
						const isNextVersionIsSame = changeLogs[changeLogsIndex + 1]?.version === log.version;
						return (
							<article key={log._id} className={clsx("relative", !isNextVersionIsSame && "mt-20")}>
								{!isNextVersionIsSame && (
									<div className="text-[10px] font-bold  top-2 absolute -left-14 -translate-x-full text-slate-900  flex items-center gap-2">
										<div className="flex flex-col">
											<span className="uppercase">
												{" "}
												{dayjs(log.created_at).format("MMM DD, YYYY")}
											</span>
											{log.version && <span>v. {log.version}</span>}
											{log.demo_link && (
												<Link to={log.demo_link} target="_blank">
													<Button
														type="default"
														icon={<Play size={12} weight="fill" />}
														className="!h-6 !text-[12px] mt-2 !font-semibold"
														onClick={() =>
															Mixpanel.track(MIXPANEL_EVENTS.CHANGE_LOG_DEMO_CLICKED, {
																version: log.version,
																demo_link: log.demo_link
															})
														}>
														Demo
													</Button>
												</Link>
											)}
										</div>
										<div className="h-0.5 w-4 flex rounded">
											<span className="w-2.5 h-full bg-slate-900 rounded" />
											<span className="w-2.5 h-full bg-slate-100 rounded" />
										</div>
									</div>
								)}

								{log.items.map((item, index) => (
									<div className="[&:not(:last-child)]:mb-8" key={item.title + index}>
										<header className="text-base mb-5 font-semibold leading-7 text-zinc-100">
											{item.title}
										</header>

										<ul className="list-disc pl-5 flex flex-col gap-3.5">
											{item.changes.map((change, index) => (
												<li
													key={change + index}
													className="text-[13px] font-normal text-zinc-200 leading-5">
													{change}
												</li>
											))}
										</ul>
										{item.note && (
											<div className="mt-5 text-[13px] font-normal text-zinc-200 leading-5">
												{item.note}
											</div>
										)}
										<div className="grid grid-cols-[repeat(3,minmax(240px,1fr))] auto-rows-[160px] gap-1 place-items-stretch image-grid mt-5">
											{item.media.map((media, _index, arr) => (
													<LargePreview
														key={media + _index}
														className="image max-h-40 grid place-items-stretch"
														s3Path={media}
													/>
												))}
										</div>
									</div>
								))}
							</article>
						);
					})}
				</div>
			</section>
		</section>
	);
};

export default Changelog;

