import { Form } from "antd";
import { isAxiosError } from "axios";
import toast from "components/Commons/Toaster";
import { VALIDATIONS_PATTERNS } from "helpers/validations";
import { checkUserExist, sendLoginOtp, verifyOtpAndSignIn } from "pages/Auth/Api";
import { useAuthContext } from "pages/Auth/Context";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";
import { Mixpanel } from "utils/mixpanel";

type TFormValues = {
	phoneEmailInput: string;
};

const useSignInLogic = () => {
	const [userAlreadyExist, setUserAlreadyExist] = useState<boolean | undefined>();
	const [activeStep, setActiveStep] = useState<"main" | "otp">("main");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [userDetails, setUserDetails] = useState<
		(TFormValues & { session_id?: string; method: "PHONE" | "EMAIL"; phone: string; email: string }) | null
	>(null);
	const [otp, setOtp] = useState("");
	const [timer, setTimer] = useState(0);
	const navigate = useNavigate();
	const { getSetUserAndNavigate } = useAuthContext();
	const [incorrectOTP, setIncorrectOTP] = useState(false);
	const [, setSearchParams] = useSearchParams();

	const otpContainerRef = useRef<HTMLDivElement>(null);

	const [SignInForm] = Form.useForm<TFormValues>();
	const phoneEmailInput = Form.useWatch(["phoneEmailInput"], SignInForm) as string;

	const startTimer = () => {
		let intervalId: NodeJS.Timeout;
		setTimer(30);
		intervalId = setInterval(() => {
			setTimer((prev) => {
				if (prev === 0) {
					clearInterval(intervalId);
					return 0;
				}
				return prev - 1;
			});
		}, 1000);
	};

	const extractPhoneEmail = (phoneEmailInput: string) => {
		const isPhone = VALIDATIONS_PATTERNS.phoneNumber.test(phoneEmailInput);
		const isEmail = VALIDATIONS_PATTERNS.validEmail.test(phoneEmailInput);
		if (isPhone) {
			return { phone: phoneEmailInput, email: "", method: "PHONE" } as const;
		}
		if (isEmail) {
			return { phone: "", email: phoneEmailInput, method: "EMAIL" } as const;
		}
		return { phone: "", email: "", method: "PHONE" } as const;
	};

	useEffect(() => {
		setSearchParams((prev) => {
			const phone = prev.get("phone");
			const email = prev.get("email");
			const isPhone = VALIDATIONS_PATTERNS.phoneNumber.test(phone || "");
			const isEmail = VALIDATIONS_PATTERNS.validEmail.test(email || "");
			if (isPhone && phone) {
				SignInForm.setFieldsValue({ phoneEmailInput: phone });
				setUserDetails((prev) => ({ ...(prev as any), phone, method: "PHONE", email: "" }));
				SignInForm.validateFields(["phoneEmailInput"]);
			}
			if (isEmail && email) {
				SignInForm.setFieldsValue({ phoneEmailInput: email });
				setUserDetails((prev) => ({ ...(prev as any), email, method: "EMAIL", phone: "" }));
				SignInForm.validateFields(["phoneEmailInput"]);
			}
			prev.delete("phone");
			prev.delete("email");
			return prev;
		});
	}, [SignInForm, setSearchParams]);

	useEffect(() => {
		setUserAlreadyExist(undefined);
		const { email, phone, method } = extractPhoneEmail(phoneEmailInput);
		setUserDetails((prev) => ({ ...(prev as any), email, phone, method }));
	}, [phoneEmailInput]);

	const onSendVerificationCode = async (values: TFormValues) => {
		try {
			setIsSubmitting(true);
			const { data } = await sendLoginOtp({
				consumer: "nucleus",
				phone: userDetails?.phone || "",
				email: userDetails?.email || "",
				method: userDetails?.method || "PHONE",
				retry: true
			});
			setOtp("");
			setUserDetails((prev) => ({ ...(prev as any), session_id: data.session_id }));
			toast.success(
				`Verification code sent successfully to your ${userDetails?.method === "PHONE" ? "phone" : "email"}`
			);
			setActiveStep("otp");
		} finally {
			setIsSubmitting(false);
		}
	};

	const onSubmitOtp = async (currentOtp: string) => {
		if (!userDetails?.session_id) return setActiveStep("main");
		const payload = {
			consumer: "nucleus",
			phone: userDetails?.phone || "",
			email: userDetails?.email || "",
			method: userDetails?.method || "PHONE"
		} as const;
		try {
			setIsSubmitting(true);
			await verifyOtpAndSignIn({
				sessionId: userDetails.session_id,
				otp: currentOtp
			});
			getSetUserAndNavigate();
			Mixpanel.track(Mixpanel.events.LOGIN_SUCCESS, payload);
			navigate("/");
		} catch (err: any) {
			if (isAxiosError(err) && err.response?.status === 400) {
				setIncorrectOTP(true);
			} else {
				toast.error("Something went wrong. Please try again");
			}
			Mixpanel.track(Mixpanel.events.LOGIN_FAILED, { ...payload, error: err.message });
		} finally {
			setIsSubmitting(false);
		}
	};

	const onResendOtp = async () => {
		if (!userDetails) return;
		const payload = {
			consumer: "nucleus",
			phone: userDetails?.phone || "",
			email: userDetails?.email || "",
			method: userDetails?.method || "PHONE",
			retry: true
		} as const;
		try {
			await sendLoginOtp(payload);
			setOtp("");
			toast.success(
				`Verification code sent successfully to your ${userDetails.method === "PHONE" ? "phone" : "email"}`
			);
			Mixpanel.track(Mixpanel.events.RESEND_OTP, payload);
			startTimer();
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		if (activeStep === "otp") {
			startTimer();
		}
	}, [activeStep]);

	useEffect(() => {
		setIncorrectOTP(false);
	}, [otp]);

	const [debouncedPhoneEmailInput] = useDebounceValue(phoneEmailInput, 400);
	useEffect(() => {
		const { email, phone, method } = extractPhoneEmail(debouncedPhoneEmailInput);
		if (!phone && !email) return setUserAlreadyExist(undefined);
		checkUserExist(method, phone, email).then(({ exists }) => {
			setUserAlreadyExist(exists);
		});
	}, [debouncedPhoneEmailInput]);

	return {
		SignInForm,
		onSendVerificationCode,
		activeStep,
		setActiveStep,
		isSubmitting,
		otp,
		setOtp,
		userDetails,
		onSubmitOtp,
		otpContainerRef,
		onResendOtp,
		timer,
		userNotExist: userAlreadyExist === false,
		isPhone: VALIDATIONS_PATTERNS.phoneNumber.test(phoneEmailInput),
		incorrectOTP
	};
};

export default useSignInLogic;

