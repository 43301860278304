import { ArrowLeft, CalendarBlank } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ children, className }: { children: React.ReactNode; className?: string }) => {
	const navigate = useNavigate();

	return (
		<header
			className={clsx(
				"w-full h-14 px-6 py-3 bg-white border-b border-slate-200 justify-between items-center inline-flex sticky top-0 shadow-sm z-50",
				className
			)}>
			<div className="justify-start items-center gap-1 flex">
				<button
					onClick={() => navigate(-1)}
					className="px-1 py-0.5 cursor-pointer bg-white rounded-md justify-center items-center gap-2 flex">
					<ArrowLeft className="w-[18px] h-[18px] text-gray-400 relative" />
				</button>
				<div className="w-[18px] h-[18px] relative grid place-items-center">
					<div className="w-px rounded-sm h-3.5 bg-gray-400" />
				</div>
				<div className="px-2 py-0.5 bg-white rounded-md justify-center items-center gap-2 flex">
					<div className="text-gray-500 text-sm font-normal font-['Inter'] leading-tight">{children}</div>
				</div>
			</div>
			<div className="justify-start items-center gap-2 flex">
				<CalendarBlank size={14} className="text-gray-500" />
				<div>
					<span className="text-gray-900 text-sm font-medium font-['Inter'] leading-tight">
						{dayjs().format("MMMM DD, ddd,")}
					</span>
					<span className="text-gray-600 text-sm font-normal font-['Inter'] leading-tight ms-1">
						{dayjs().format("hh:mm A")}
					</span>
				</div>
			</div>
		</header>
	);
};

export default PageHeader;

