import clsx from "clsx";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

interface OTPInputProps {
	length?: number;
	onChange?: (otp: string) => void;
	onComplete: (otp: string) => void;
	hasError?: boolean;
	clearField?: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({ length = 4, clearField, onChange, onComplete, hasError }) => {
	const [otp, setOtp] = useState(new Array(length).fill(""));
	const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

	useEffect(() => {
		if (clearField) {
			setOtp(new Array(length).fill(""));
			inputsRef.current[0]?.focus();
		}
	}, [clearField, length]);

	useEffect(() => {
		const currentOtp = otp.join("");
		onChange?.(currentOtp);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otp, length]);

	useEffect(() => {
		const currentOtp = otp.join("");
		if (currentOtp.length === length) {
			onComplete(currentOtp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otp, length]);

	const focusInput = (index: number, forward: boolean) => {
		const nextIndex = forward ? index + 1 : index - 1;
		if (nextIndex >= 0 && nextIndex < length) {
			inputsRef.current[nextIndex]?.focus();
		}
	};

	const handleOtpChange = (element: HTMLInputElement, index: number) => {
		const value = element.value;
		if (!/^\d*$/.test(value)) {
			return;
		}
		const newOtp = [...otp];
		newOtp[index] = value.substring(value.length - 1, value.length);
		setOtp(newOtp);
		if (value && index < length - 1) {
			focusInput(index, true);
		}
	};

	const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
		if (event.key === "Backspace" && !event.currentTarget.value) {
			focusInput(index, false);
		}
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const pasteData = event.clipboardData.getData("text").trim();
		if (pasteData.length === length && /^\d+$/.test(pasteData)) {
			setOtp(pasteData.split(""));
			focusInput(length - 1, true);
		}
	};

	return (
		<div className="grid grid-cols-4 place-items-center gap-2.5">
			{otp.map((data, index) => (
				<input
					className={clsx(
						`focus: h-12 max-w-16 rounded-md bg-neutral-50 px-2 text-center
						font-['Inter'] text-xl font-normal leading-7 !outline-none 
                     transition-all duration-200 ease-out-1 focus:ring-1
                     md:max-w-20 
                    `,
						hasError
							? "text-rose-600 shadow-[0px_1px_3px_0px_rgba(17,_24,_39,_0.16),_0px_0px_0px_1px_#E11D48,_0px_0px_0px_3px_rgba(214,_29,_71,_0.36)] focus:ring-rose-500"
							: "text-indigo-500 shadow-[0px_1px_2px_0px_rgba(17,_24,_39,_0.08),_0px_0px_0px_1px_rgba(17,_24,_39,_0.10)] focus:shadow-[0px_1px_2px_0px_rgba(17,_24,_39,_0.08),_0px_0px_0px_2px_#E0E7FF] focus:ring-primary-500"
					)}
					key={index}
					type="text"
					maxLength={1}
					autoFocus={index === 0}
					value={data}
					ref={(ref) => (inputsRef.current[index] = ref)}
					onChange={(e) => handleOtpChange(e.target, index)}
					onKeyUp={(e) => handleKeyUp(e, index)}
					onPaste={handlePaste}
					onFocus={(e) => e.target.select()}
					inputMode="numeric"
				/>
			))}
		</div>
	);
};

export default OTPInput;

