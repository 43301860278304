import { RouteProps } from "react-router-dom";
import SignIn from "./Components/SignIn";

const routes: RouteProps[] = [
	{
		path: "/sign-in",
		element: <SignIn />
	}
];

export default routes;

