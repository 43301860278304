export const VALIDATIONS_PATTERNS = {
	number: /^\d+$/,
	passport: /^(?:[A-Z]\d{7}|[A-Z]{2}\d{6})$/,
	singleName: /^[A-Za-z'][A-Za-z\s']+$/,
	fullName: /^[a-zA-Z']+\s[a-zA-Z'\s]+$/,
	phoneNumber: /^[6-9][0-9]{9}$/,
	validEmail:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

