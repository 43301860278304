import dayjs from "dayjs";

export * from "./validations";

export const daysMap = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const monthMap = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];
export const getNumberOfDays = (year: number, month: number) => {
	return 40 - new Date(year, month, 40).getDate();
};

export const getMonthStr = (month: number) => monthMap[Math.max(Math.min(11, month), 0)] || "Month";

export const clone = (item: any) => {
	if (!item) {
		return item;
	}
	const types = [Number, String, Boolean];
	let result: any;
	types.forEach((type) => {
		if (item instanceof type) {
			result = type(item);
		}
	});
	if (typeof result === "undefined") {
		if (Object.prototype.toString.call(item) === "[object Array]") {
			result = [];
			item.forEach((child: any, index: number) => {
				result[index] = clone(child);
			});
		} else if (typeof item === "object") {
			result = {};
			for (const i in item) {
				if (item[i] instanceof Date) {
					result[i] = new Date(item[i]);
				} else {
					result[i] = clone(item[i]);
				}
			}
		} else {
			result = item;
		}
	}
	return result;
};
export const formatDate = (startDateStr = "", endDateStr = "") => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [dd, mm, date, yy] = startDateStr.split(" ");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [e_dd, e_mm, e_date, e_yy] = endDateStr.split(" ");
	return `${date} ${mm}, ${yy.substr(2, 4)} - ${e_date} ${e_mm}, ${e_yy.substr(2, 4)}`;
};

export const capitalizeFirstLetter = (str: string) => {
	try {
		return str.charAt(0).toUpperCase() + str.slice(1);
	} catch (err) {
		return str;
	}
};

export const capitalizeEachWord = (str: string) => {
	try {
		return str
			.split(" ")
			.map((_) => capitalizeFirstLetter(_))
			.join(" ");
	} catch (err) {
		return str;
	}
};

export const capitalizeAndSnakeCase = (str: string) => {
	return str.toUpperCase().split(" ").join("_");
};
export const getDateStr = (utc: string) => {
	try {
		const dt = new Date(utc).toDateString();
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [dd, mm, date, yy] = dt.split(" ");
		return `${date} ${mm} ${yy}`;
	} catch (err) {
		return utc;
	}
};

export const getFormattedDate = (date: Date | string | number) => {
	if (typeof date === "string") {
		date = new Date(date);
	}
	if (typeof date === "number") {
		date = new Date(date);
	}
	if (!(date instanceof Date)) {
		return "Invalid Date";
	}
	return getDateStr(date.toUTCString());
};

export const getFormattedDateWithTime = (date: Date | string | number) => {
	if (typeof date === "string") {
		date = new Date(date);
	}
	if (typeof date === "number") {
		date = new Date(date);
	}
	if (!(date instanceof Date)) {
		return "Invalid Date";
	}
	return dayjs(date).format("MMM DD, YYYY, hh:mm A");
};

export function formatDateRange(startDate: Date | string | number, endDate: Date | string | number) {
	const start = new Date(startDate);
	const end = new Date(endDate);
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	if (start.getFullYear() === end.getFullYear()) {
		const formattedStartDate = `${start.getDate()} ${monthNames[start.getMonth()]} - ${end.getDate()} ${
			monthNames[end.getMonth()]
		} ${start.getFullYear()}`;
		return formattedStartDate;
	} else {
		const formattedStartDate = `${start.getDate()} ${
			monthNames[start.getMonth()]
		} ${start.getFullYear()} - ${end.getDate()} ${monthNames[end.getMonth()]} ${end.getFullYear()}`;
		return formattedStartDate;
	}
}

export const getScoreByStatus = (status: string) => {
	if (status === "PENDING") {
		return Math.floor(100 / 3);
	}
	if (status === "AWAITING") {
		return Math.floor(100 / 3) * 2;
	}
	return 100;
};
export const calcScore = (allStatus: string[] | undefined) => {
	const scores = allStatus?.map((i) => getScoreByStatus(i));
	const score = Math.max(...(scores || [1]));
	return score;
};
export const setLocalStorageWithTtl = (key: string, value: any, ttl: number) => {
	const now = new Date();

	const item = {
		value,
		expiry: now.getTime() + ttl
	};
	localStorage.setItem(key, JSON.stringify(item));
};
export const getFromLocalStorage = (key: string) => {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
};

// Returns whether the application is running as electron app or on server
export const isElectron = () => {
	// Renderer process
	if (
		typeof window !== "undefined" &&
		typeof window.process === "object" &&
		(window.process as any).type === "renderer"
	) {
		return true;
	}

	// Main process
	if (typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron) {
		return true;
	}

	// Detect the user agent when the `nodeIntegration` option is set to true
	if (
		typeof navigator === "object" &&
		typeof navigator.userAgent === "string" &&
		navigator.userAgent.indexOf("Electron") >= 0
	) {
		return true;
	}

	return false;
};

// This function will return the folder name (country) containing automation script, and automation script name
// [{folder_name}, {script_name}]
// e.g: ["malaysia", "MalaysiaVisaApp"]
// This will be used to build path to trigger specific country
export const getVisaAutomationScriptName = (countrySymbol: string) => {
	if (!countrySymbol) return ["", ""];
	else if (countrySymbol.toUpperCase() === "MYS") return ["malaysia", "MalaysiaVisaApp"];
	else if (countrySymbol.toUpperCase() === "VNM") return ["vietnam", "VietnamVisaApp"];
	else return ["", ""];
};

export function getContentTypeFromExtension(ext: string) {
	const extensionToContentType: Record<string, string> = {
		pdf: "application/pdf",
		jpg: "image/jpeg",
		jpeg: "image/jpeg",
		png: "image/png"
		// Add more mappings as needed
	};

	return extensionToContentType[ext.toLowerCase()] || "application/octet-stream";
}

export function truncate(str: string, length = 30) {
	if (str.length > length) {
		const firstPart = str.slice(0, (length / 2) | 0);
		const secondPart = str.slice((-length / 2) | 0);
		return `${firstPart}...${secondPart}`;
	}
	return str;
}

export const getFullName = (
	firstName: string,
	middleName: string | undefined | null,
	lastName: string | null | undefined
) => `${firstName || ""} ${middleName || ""} ${lastName || ""}`.replace(/\s\s+/g, " ");

export function getChildOrAdult(dob: string): TAgeGroup {
	const today = new Date();
	const birthDate = new Date(dob);

	let age = today.getFullYear() - birthDate.getFullYear();
	const monthDiff = today.getMonth() - birthDate.getMonth();

	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}

	return age >= 18 ? "ADULT" : "CHILD";
}

export function getDisplayableDate(date: Date): string {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const day = date.getDate().toString().padStart(2, "0");
	const month = months[date.getMonth()];
	const year = date.getFullYear().toString();
	return `${day} ${month} ${year}`;
}

export function numberToWords(_no: number): string {
	const _numberToWords = (no: number) => {
		const words: Record<number, string> = {
			0: "",
			1: "One",
			2: "Two",
			3: "Three",
			4: "Four",
			5: "Five",
			6: "Six",
			7: "Seven",
			8: "Eight",
			9: "Nine",
			10: "Ten",
			11: "Eleven",
			12: "Twelve",
			13: "Thirteen",
			14: "Fourteen",
			15: "Fifteen",
			16: "Sixteen",
			17: "Seventeen",
			18: "Eighteen",
			19: "Nineteen",
			20: "Twenty",
			30: "Thirty",
			40: "Forty",
			50: "Fifty",
			60: "Sixty",
			70: "Seventy",
			80: "Eighty",
			90: "Ninety",
			100: "Hundred",
			1000: "Thousand",
			100000: "Lakh",
			10000000: "Crore"
		};

		if (no === 0) {
			return "";
		}

		let noValue = "";
		let highNo = no;
		let remainNo = 0;
		let value = 100;
		let value1 = 1000;

		while (no >= 100) {
			if (value <= no && no < value1) {
				noValue = words[value];
				highNo = Math.floor(no / value);
				remainNo = no % value;
				break;
			}
			value = value1;
			value1 *= 100;
		}

		if (words[highNo]) {
			return words[highNo] + " " + noValue + " " + numberToWords(remainNo);
		} else {
			const unit = highNo % 10;
			const ten = Math.floor(highNo / 10) * 10;
			return words[ten] + " " + words[unit] + " " + noValue + " " + numberToWords(remainNo);
		}
	};

	return _numberToWords(_no).trim();
}

export const isNumber = (val: any) => typeof val === "number" && !isNaN(val);

export const isFunction = (value: any) =>
	value
		? Object.prototype.toString.call(value) === "[object Function]" ||
			"function" === typeof value ||
			value instanceof Function
		: false;

export const isEditableElement = (el: Element | null) => {
	if (el instanceof HTMLElement && el.isContentEditable) return true;
	if (el instanceof HTMLInputElement) {
		// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
		if (/text|email|number|password|search|tel|url/.test(el.type || "")) {
			return !(el.disabled || el.readOnly);
		}
	}
	if (el instanceof HTMLTextAreaElement) return !(el.disabled || el.readOnly);
	return false;
};

