import { WarningOctagon } from "@phosphor-icons/react";
import axios from "axios";
import toast from "components/Commons/Toaster";
import { API_BASE_URL } from "env";
import { setupRequestInterceptors, setupResponseInterceptors } from "./setupInterceptors";

const apiInstance = axios.create({
	baseURL: `${API_BASE_URL}/v1`,
	withCredentials: true
});

setupRequestInterceptors(apiInstance);

setupResponseInterceptors(
	apiInstance,
	(res) => {
		const status = res.status;
		const url = res.config.url;
		if (status === 200 && url === "/users/current") {
			localStorage.setItem("session-expired", "0");
		}
	},
	(error) => {
		if (axios.isCancel(error)) return error;
		const status = error?.response?.status || 0;
		if (status === 500)
			toast.error(`API Error: ${status}`, {
				icon: <WarningOctagon />,
				description: error?.response?.data?.message
			});
		else if (status === 401) {
			localStorage.setItem("session-expired", "1");
		} else toast.error(error?.response?.data?.message || "Something went wrong");

		return error;
	}
);

export { apiInstance };
