import { Play } from "@phosphor-icons/react";
import { Modal } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { Button } from "x-wings";
import "./styles.scss";

export type TNewChangeLogModalPropsType = {
	release: TRelease;
	open: boolean;
	onClose: () => void;
};

const NewChangeLogModal: React.FC<TNewChangeLogModalPropsType> = ({ release, open, onClose }) => {
	useEffect(() => {
		if (open) {
			Mixpanel.track(MIXPANEL_EVENTS.CHANGE_LOG_MODAL_OPENED);
			Mixpanel.time_event(MIXPANEL_EVENTS.CHANGE_LOG_MODAL_CLOSED);
		}
	}, [open]);

	const handleClose = () => {
		Mixpanel.track(MIXPANEL_EVENTS.CHANGE_LOG_MODAL_CLOSED);
		onClose();
	};

	return (
		<Modal
			prefixCls="new-change-log"
			open={open}
			onCancel={handleClose}
			title={`Latest Release`}
			footer={
				<div className="flex gap-2 [&>*]:flex-1">
					<Link to="/changelog" className="">
						<Button type="default" onClick={handleClose} block>
							View all change logs
						</Button>
					</Link>
					<Button onClick={handleClose} type="primary">
						Close
					</Button>
				</div>
			}>
			<article className="relative p-4 max-h-[500px] overflow-auto scrollbar-hidden">
				<div className="flex gap-2 text-[13px] text-gray-700 leading-none items-end mb-4">
					{release.version && (
						<span className="text-base font-semibold leading-none">v. {release.version}</span>
					)}
					<span className="uppercase text-gray-500"> {dayjs(release.created_at).format("MMM DD, YYYY")}</span>
					{release.demo_link && (
						<Link to={release.demo_link} target="_blank" className="ms-auto self-center">
							<Button
								type="default"
								icon={<Play size={12} weight="fill" />}
								className="!h-6 !text-[12px] !font-semibold"
								onClick={() =>
									Mixpanel.track(MIXPANEL_EVENTS.CHANGE_LOG_DEMO_CLICKED, {
										version: release.version,
										demo_link: release.demo_link
									})
								}>
								Demo
							</Button>
						</Link>
					)}
				</div>

				{release.items.map((item, index) => (
					<div className="[&:not(:last-child)]:mb-4" key={item.title + index}>
						<header className="text-[15px] font-semibold leading-7 text-gray-700 mb-1">{item.title}</header>

						<ul className="list-disc pl-5 flex flex-col gap-2 mb-2">
							{item.changes.map((change) => (
								<li className="text-[14px] font-normal text-gray-600 leading-none">{change}</li>
							))}
						</ul>
						{item.note && (
							<div className="text-[14px] font-normal text-gray-600 leading-5">{item.note}</div>
						)}
					</div>
				))}
			</article>
		</Modal>
	);
};

export default NewChangeLogModal;

