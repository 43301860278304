import clsx from "clsx";
import React, { useRef } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useResizeObserver } from "usehooks-ts";
import { LogoBar } from "./Components/LogoBar";
import routes from "./routes";
import "./styles.scss";

const Auth: React.FC = () => {
	const navigate = useNavigate();
	const currentDivRef = useRef<HTMLDivElement>(null);
	const authPageCardRef = useRef<HTMLDivElement>(null);

	const onLogoCLick = () => navigate("/");

	useResizeObserver({
		ref: currentDivRef,
		onResize: ({ height }) => {
			if (authPageCardRef.current && height) {
				authPageCardRef.current.style.height = `${height + 40}px`;
			}
		}
	});

	return (
		<div className="min-w-screen max-h-dvh overflow-hidden bg-slate-100 px-5 md:px-8">
			<div className="auth-page-container">
				<div id="auth-error-banner" />
				<div className="relative z-10 col-span-12 h-fit justify-center">
					<LogoBar imageClassName={clsx("cursor-pointer")} onLogoClick={onLogoCLick} />
				</div>

				<div className="items-star relative hidden self-stretch justify-self-stretch md:col-start-1  md:col-end-5 md:block ">
					<span className="dotted-line absolute -inset-y-full left-[38px] w-px" />
				</div>

				<div className="auth-page-content relative isolate col-span-12 self-stretch justify-self-stretch md:col-start-5 md:col-end-13">
					<div className="background-logo pointer-events-none absolute bottom-[-270px] left-[162px] -z-30 h-[622px] w-[620px]  md:bottom-[-450px] md:left-[118px] md:h-[1105px] md:w-[1100px]" />

					<div className="relative -mx-px w-full overflow-clip p-px pb-[35px] md:max-w-[392px]">
						<div
							ref={authPageCardRef}
							className="auth-page-content-inner inline-flex w-full  flex-col items-start
						justify-start gap-8 overflow-hidden rounded-2xl bg-[#FCFCFD]
						p-4 shadow-[0px_0px_0px_1px_rgba(13,_18,_28,_0.06),_0px_1px_1px_-0.5px_rgba(13,_18,_28,_0.06),_0px_3px_3px_-1.5px_rgba(13,_18,_28,_0.06),_0px_6px_6px_-3px_rgba(13,_18,_28,_0.06),_0px_12px_12px_-6px_rgba(13,_18,_28,_0.06),_0px_24px_24px_-12px_rgba(13,_18,_28,_0.06),_0px_1px_0px_0px_#FFF_inset] transition-[height] duration-150  
						 ease-out-5  md:w-[390px] md:p-5 ">
							<div
								ref={currentDivRef}
								className="grid w-full grid-cols-1 grid-rows-1 place-items-stretch">
								<Routes>
									{routes.map((props) => (
										<Route key={props.path} {...props} />
									))}
								</Routes>
							</div>
						</div>

						<div
							className={clsx(
								"absolute inset-x-5 bottom-8 -z-10 h-20 rounded-[100%] bg-gradient-to-r from-[#00FFF0] to-[#B132FF] blur-[18px] transition-opacity duration-200 ease-in"
							)}
						/>
					</div>

					<span className="dotted-line pointer-events-none absolute -inset-y-full left-0 -z-10 w-px" />
					<span className="dotted-line pointer-events-none absolute -inset-y-full left-[calc(100%_-_3px)] -z-20 w-px md:left-[389px]" />

					<div className=" pointer-events-none absolute bottom-[30px] left-0 -z-10 hidden h-[100px] w-full items-center justify-between gap-0.5 px-2 md:bottom-[52px] md:justify-start md:gap-[56px] md:px-8">
						<img
							className="max-w-[20%]  md:max-w-full"
							src="/assets/icons/auth/makemytrip.svg"
							alt="MakeMyTrip"
						/>
						<span className="h-4 min-w-px rounded bg-gray-300" />
						<img
							className="max-w-[20%] md:max-w-full"
							src="/assets/icons/auth/travclan.svg"
							alt="TravClan"
						/>
						<span className="h-4 min-w-px rounded bg-gray-300" />
						<img
							className="max-w-[20%]  md:max-w-full"
							src="/assets/icons/auth/easemytrip.svg"
							alt="EaseMyTrip"
						/>
						<span className="h-4 min-w-px rounded bg-gray-300" />
						<img
							className="max-w-[20%] md:max-w-full"
							src="/assets/icons/auth/pickyourtrail.svg"
							alt="Pick your Trail"
						/>
					</div>

					<div className="hidden absolute bottom-8 left-1/2 -z-10 -translate-x-1/2 whitespace-nowrap md:hidden">
						<span className="font-['Inter Display'] text-xs font-normal leading-none text-gray-500">
							©️ StampMyVisa 2024&nbsp;
						</span>
						<span className="font-['Inter Display'] text-xs font-thin leading-none text-gray-500">•</span>
						<span className="font-['Inter Display'] text-xs font-normal leading-none text-gray-500"> </span>
						<Link
							to="./privacy"
							className="font-['Inter Display'] text-xs font-normal leading-none text-gray-500 underline">
							Privacy Policy
						</Link>
						<span className="font-['Inter Display'] text-xs font-normal leading-none text-gray-500">
							&nbsp; &&nbsp;
						</span>
						<Link
							to="terms"
							className="font-['Inter Display'] text-xs font-normal leading-none text-gray-500 underline">
							Terms
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Auth;

