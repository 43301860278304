import React from "react";

type WhenProps = {
    isTrue?: any;
    children: React.ReactNode;
};

const When :React.FC<WhenProps> = ({
  isTrue = false,
  children,
}) => {
  if (isTrue) {
    return (
      <>
        {children}
      </>
    );
  }
  return null;
};

export default When;