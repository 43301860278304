import { ArrowClockwise, House } from "@phosphor-icons/react";
import axios from "axios";
import { isProduction } from "env";
import React, { Component, ErrorInfo } from "react";
import { Button } from "x-wings";
import ErrorStateImage from "./Assets/ErrorState.png";

interface Props {
	children: React.ReactNode;
	user: AuthUser;
}

interface State {
	hasError: boolean;
	error: string;
	user: AuthUser;
}

interface IErrorPayload {
	content: string;
	username: string;
	embeds?: Embed[];
}

interface Embed {
	author: {
		name: string;
	};
	fields: {
		name: string;
		value: string;
	}[];
}

const isLocalHost = window.location.hostname === "localhost";

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false, error: "", user: props.user };
	}

	onRetry = () => {
		window.location.reload();
	};

	gotoDashboard = () => {
		window.location.href = "/";
	};

	sendErrorToDiscord = async (error: Error, user: AuthUser) => {
		if (!isProduction || isLocalHost) return;
		if (error && error.toString().includes("ChunkLoadError")) return;

		try {
			const payload: IErrorPayload = {
				content: "",
				username: "Gangadhar",
				embeds: [
					{
						author: {
							name: "Error in Nucleus"
						},
						fields: [
							{
								name: "Error:",
								value: error.toString()
							},
							{
								name: "URL:",
								value: window.location.href
							},
							{
								name: "User:",
								value: user
									? `- Name: ${user?.first_name} ${user?.last_name}\n- Phone: ${user.phone}\n- Org: ${user.organisation_id.name}`
									: "Not logged in"
							},
							{
								name: "Team:",
								value: "<@&994672541157302282>"
							},
							{
								name: "Environment:",
								value: "Production"
							}
						]
					}
				]
			};

			if (error.stack && payload.embeds) {
				payload.embeds[0].fields.push({
					name: "Stack Trace:",
					value: error.stack
				});
			}

			await axios.post(
				"https://discord.com/api/webhooks/1191320001563590707/1LjHWy6u66fOCfD5NfiDYzlNl4JJKT_e5Ir4bL-3gu3CeO-XIZopWj3I4BKUGVixplvF",
				payload
			);
		} catch (error) {
			console.log("🚀 ~ file: index.tsx:28 ~ ErrorBoundary ~ error:", error);
		}
	};

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		if (error.toString().includes("ChunkLoadError")) {
			return window.location.reload();
		}
		this.sendErrorToDiscord(error, this.props.user);
		this.setState({ hasError: true, error: error.toString() });
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="grid h-screen w-screen place-items-center">
					<div className="inline-flex h-96 max-w-96 flex-col items-center justify-center gap-12">
						<div>
							<img src={ErrorStateImage} alt="Error" height={210} width={320} />
						</div>
						<div className="flex flex-col items-center justify-start gap-6">
							<div className="flex flex-col items-center justify-start gap-2">
								<div className="text-center font-['Inter'] text-3xl font-semibold leading-9 text-gray-600">
									Uh-oh, something went wrong!
								</div>
								<div className="font-['Inter'] text-base font-medium leading-normal text-gray-500">
									Please try again after some time
								</div>
							</div>
							<div className="grid grid-cols-2 gap-3">
								<Button type="default" onClick={this.onRetry}>
									Retry
									<span className="ant-btn-icon">
										<ArrowClockwise weight="bold" size={16} />
									</span>
								</Button>
								<Button type="primary" onClick={this.gotoDashboard}>
									Go to Dashboard
									<span className="ant-btn-icon">
										<House size={16} weight="bold" />
									</span>
								</Button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

